.View {
    position: absolute;
    left: 1em;
    right: 1em;
    top: 0;
    bottom: 0;
    background: #fff;
    overflow-y: hidden;
}
.View h2 {
    position: absolute;
    right: 0em;
    top: 0em;
}

.View .ResponsiveWrapper {
    height: 100%;
}