body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .valuetrees h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .valuetrees p {
  color: #666;
}

.Home .valuetrees .view-link {
  position: absolute;
  right: 1em;
  top: 1em;
  color: #000;
}
.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

.NewValuetree button {
    margin-right: 10px;
}

.MergeValuetrees #valuetree-list .selected {
    background: #b8daff;
}

.MergeValuetrees #valuetree-list .source-tree {
    background: #d6d8db;
}
.ReactModalPortal h3 {

}

.ReactModalPortal .btn {
    margin: 1.5px;
}

.Valuetree button {
    margin-right: 10px;
    margin-bottom: 5px;
}

.hiddenNodes {
    padding: 5px;
}

.FullScreenMode {
    position: absolute;
    left: 1em;
    right: 1em;
    top: 0;
    background: #fff;
    height: 100%;
    padding-top: 1em;
}

.FullScreenMode form {
    height: 100%;
}

.FullScreenMode .ResponsiveWrapper {
    height: 100%;
}

.ControlBox {
    background: rgba(240, 240, 240, .5);
    
    border: 1px solid #999;
    display: inline-block;
    padding: .5em;
}

.control-label {
    padding-right: 1em;
}
.View {
    position: absolute;
    left: 1em;
    right: 1em;
    top: 0;
    bottom: 0;
    background: #fff;
    overflow-y: hidden;
}
.View h2 {
    position: absolute;
    right: 0em;
    top: 0em;
}

.View .ResponsiveWrapper {
    height: 100%;
}
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
.NotFound {
  padding-top: 100px;
  text-align: center;
}
.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}
