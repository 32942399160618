.ReactModalPortal h3 {

}

.ReactModalPortal .btn {
    margin: 1.5px;
}

.Valuetree button {
    margin-right: 10px;
    margin-bottom: 5px;
}

.hiddenNodes {
    padding: 5px;
}

.FullScreenMode {
    position: absolute;
    left: 1em;
    right: 1em;
    top: 0;
    background: #fff;
    height: 100%;
    padding-top: 1em;
}

.FullScreenMode form {
    height: 100%;
}

.FullScreenMode .ResponsiveWrapper {
    height: 100%;
}

.ControlBox {
    background: rgba(240, 240, 240, .5);
    
    border: 1px solid #999;
    display: inline-block;
    padding: .5em;
}

.control-label {
    padding-right: 1em;
}